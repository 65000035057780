
import { CustomField } from "@/models";
import { BIconCollectionPlay } from "bootstrap-vue";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { translations, eventHandler } from "@/mixins";
import { CustomFields, LocationFacilities } from "@/services/SOLO";
import { Table, TableColumn } from "element-ui";

interface CustomFieldObject {
  "custom-field": string;
  "custom-field-id": string;
  value: string;
}

interface CustomFieldItemObject {
  id: string;
  value: string;
}

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LoadingPanel: () => import("@/components/LoadingPanel"),
    LocationCustomFieldModal: () => import("./LocationCustomFieldModal"),
  },
  mixins: [translations, eventHandler],
})
export default class LocationCustomFields extends Vue {
  @Prop() locationCustomFields!: Array<CustomFieldObject>;
  @Prop() locationId!: string;
  @Prop() loading!: Boolean;

  localLocationCustomFields: Array<CustomFieldObject> = [];

  // All concept custom fields
  customFields: Array<CustomField> = [];
  activeCustomField: CustomFieldItemObject = {
    id: "",
    value: "",
  };
  removingFacility: Boolean = false;

  $notify: any;
  $refs!: {
    locationCustomFieldModal: any;
  };
  confirm!: Function;
  translate!: Function;

  constructor() {
    super();
  }

  @Watch("locationCustomFields", { immediate: true, deep: true })
  locationCustomFieldsChanged(
    newVal: Array<CustomFieldObject>,
    oldVal: Array<CustomFieldObject>
  ) {
    this.localLocationCustomFields = newVal;
  }

  mounted() {
    if (this.locationCustomFields) {
      this.localLocationCustomFields = this.locationCustomFields;
    }
  }

  created() {
    this.fetchCustomFields();
  }

  private async fetchCustomFields() {
    let response = await CustomFields.all();
    this.customFields = response.data.data;
    console.log(this.customFields);
  }

  private updateItem(customField: CustomFieldObject) {
    this.$refs.locationCustomFieldModal.open = true;
    this.activeCustomField = {
      id: customField["custom-field-id"].toString(),
      value: customField.value,
    };
  }

  private deleteItem(customField: CustomFieldObject) {
    this.confirm(
      this.$bvModal,
      this.translate("Are you sure you want to delete this item?")
    ).then((value: boolean) => {
      if (value) {
        CustomFields.deleteData(customField["custom-field-id"], {
          entity: "location",
          "entity-id": this.locationId,
        })
          .then((response: any) => {
            let index = this.localLocationCustomFields.findIndex(item => +item['custom-field-id'] === +customField['custom-field-id']);
            this.localLocationCustomFields.splice(index, 1);

            this.$notify({
              title: this.translate("DATA DELETED"),
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: this.translate("Custom field successfully deleted"),
              type: "success",
              icon: "fas fa-trash",
            });
          })
          .catch((err: any) => {
            this.$notify({
              title: this.translate("SYSTEM ERROR!"),
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: this.translate("Something went wrong, please try again!"),
              type: "danger",
              icon: "fas fa-bomb",
            });
          });
      }
    });
  }

  private createCustomField() {
    this.$refs.locationCustomFieldModal.open = true;
    this.activeCustomField = {
      id: "",
      value: "",
    };
  }

  private addItemToList(data: CustomFieldObject) {
    if (!this.localLocationCustomFields) {
      this.localLocationCustomFields = [data];
      return;
    }
    
    let customField = this.localLocationCustomFields.find(
      (item) => +item["custom-field-id"] === +data["custom-field-id"]
    );

    if (customField) {
      customField.value = data.value;
    } else {
      this.localLocationCustomFields.push(data);
    }
  }
}
